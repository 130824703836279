<template>
  <div class="question-list" :class="shrinkQuestion?'shrink-question':''" @click.stop>
    <QuestionList
      :courseId="courseId"
      :questionList="questionList"
      @updateQuestionList="getQuestionList"
    ></QuestionList>
    <div class="close-btn" @click="$emit('hideQuestion')">
      <img src="@/assets/img/icon-close.png" alt="" class="close" />
    </div>
    <div class="question-input">
      <a-textarea
        class="feedback-input"
        :rows="4"
        placeholder="请输入您要提出的问题："
        v-model="question"
        @pressEnter="askQuestion"
      />
      <div class="ask-btn-wrapper">
        <button class="ask-btn" @click="askQuestion">提问</button>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionList from "./QuestionList.vue";
import {sid} from "@/utils/api.js";

export default {
  components: { QuestionList },

  props: {
    courseId: Number,
    token: String,
  },
  data() {
    return {
      questionList: [],
      question: "",
      groupId: "",
    };
  },
  mounted() {
    this.groupId = this.$route.query.groupid;
    this.getQuestionList();
  },
  computed: {
    shrinkQuestion() {
      const height = document.documentElement.clientHeight;
      if (height <= 664) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 提问
    askQuestion() {
      if (this.question.trim() === "") {
        this.$message.error("问题不能为空");
        return false;
      }
      const url = `https://www.msup.com.cn/wechat/small-program/ask-questions?sid=${sid(this.global.year)}&courseId=${this.courseId}&token=${this.token}&question=${this.question}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.success === true) {
            this.$message.success("提问成功");
            this.question = "";
            this.getQuestionList();
          } else {
            console.log("提问失败");
          }
        });
    },
    //  问题列表
    getQuestionList() {
      const url = `https://www.msup.com.cn/wechat/small-program/course-and-ppt?sid=${sid(this.global.year)}&cid=${this.courseId}&token=${this.token}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.success === true) {
            this.questionList = res.data.question;
          } else {
            console.log("获取问题列表失败");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.question-list {
  position: fixed;
  bottom: 80px;
  right: 0;
  background: #f9f9fa;
  border: 1px solid #dddddd;
  width: 670px;
  box-sizing: border-box;
  padding: 30px 16px 15px 16px;
  z-index: 150;
  transition: all linear 0.3s;
  &.shrink-question {
    max-height: 400px;
    overflow: auto;
  }
  .close-btn {
    position: absolute;
    top: 6px;
    right: 6px;
    img {
      width: 24px;
    }
  }
  .question-content {
    background-color: #fff;
    padding: 30px 22px 35px;
    // padding: 30px 22px 49px;
    h4 {
      color: #333333;
      font-size: 19px;
      font-weight: 400;
    }
    .ask-list {
      margin-top: 16px;
      // max-height: 267px;
      max-height: 170px;
      padding-bottom: 9px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      background: rgba(243, 244, 246, 0.5);
      .ask-item {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 28px 24px 37px;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 37px;
          right: 28px;
          width: auto;
          height: 1px;
          background: #dddddd;
        }
        &:last-child::after {
          display: none;
        }
        .ask-left {
          max-width: 420px;
          .ask-user {
            color: #2e65a8;
            font-size: 14px;
          }
          .user-question {
            margin-top: 12px;
            line-height: 21px;
            font-size: 14px;
            color: #999999;
          }
        }
        .ask-right {
          .same-ask {
            border: none;
            outline: none;
            width: 74px;
            height: 32px;
            background: rgba(46, 101, 168, 0.15);
            border-radius: 4px;
            color: #0a70c5;
            font-size: 17px;
            letter-spacing: 1px;
            cursor: pointer;
            &.selected {
              background: #0a70c5;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .question-input {
    margin-top: 10px;
    background-color: #fff;
    padding: 32px 20px 26px;
    textarea {
      border-radius: 8px;
      border-color: #ededed;
      width: 600px;
      height: 120px;
      padding: 14px 10px;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 2px;
      &:focus {
        border-color: gray;
        outline-color: gray;
      }
      &:focus-visible {
        border-color: lightgray;
        outline-color: lightgray;
      }
      &::-webkit-input-placeholder {
        color: #999999;
      }
      &::-moz-placeholder {
        color: #999999;
      }
      &:-ms-input-placeholder {
        color: #999999;
      }
      &::-webkit-input-placeholder {
        color: #999999;
      }
      &::placeholder {
        color: #999999;
      }
    }
    .ask-btn-wrapper {
      margin-top: 20px;
      text-align: right;
      .ask-btn {
        border: none;
        outline: none;
        width: 82px;
        height: 33px;
        background: #dce5ef;
        color: #0a70c5;
        font-size: 17px;
        border-radius: 4px;
        cursor: pointer;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
