<template>
  <div class="schedule">
    <div class="schedule-bar">
      <ul class="time-list">
        <li
          v-for="(item, index) in Object.keys(detail)"
          :class="selectedTime === item ? 'selected' : ''"
          :key="'live_schedule_'+index"
          @click="toggleTime"
          :data-index="item"
        >
          <span class="day">Day 0{{ index + 1 }}</span>
          <span>({{ formatTime(item) }})</span>
        </li>
      </ul>
    </div>
    <div class="schedule-content pc" ref="scheduleContent">
      <ul class="content-list" v-if="detail[selectedTime]">
        <li
          v-for="(item, index) in detail[selectedTime].all"
          :key="'live_schedule_list_'+index"
          ref=""
        >
          <div
            v-if="item[groupId]"
            class="case-title"
            @click="toggleContent"
            :data-id="item[groupId].courseId"
          >
            <div class="photo-wrapper">
              <img
                v-for="(lecturer, i) in item[groupId].lecturers"
                :key="i"
                :src="lecturer.thumbs"
                class="photo"
              />
            </div>
            <div class="lecturer-wrapper">
              <div class="title-wrapper">
                <h3 class="title">{{ item[groupId].courseTitle }}</h3>
                <button
                  class="ask-question"
                  @click.stop="showQuestion(item[groupId].courseId)"
                  v-if="global.year!='2022sh'"
                >
                  提问
                </button>
              </div>
              <div
                class="lecturer"
                v-for="lecturer in item[groupId].lecturers"
                :key="lecturer.lid"
              >
                <div class="teacher">
                  <span class="teacher-name">{{ lecturer.name }}</span>
                  <img :src="item[groupId].logo" alt="" class="logo" />
                </div>
                <div class="company-time">
                  <div class="company">
                    {{ lecturer.company }} {{ lecturer.position }}
                  </div>
                  <div class="time">{{ index }}</div>
                </div>
              </div>
            </div>
            <AskQuestion
              v-if="item[groupId].courseId === selectedQuestion"
              :courseId="item[groupId].courseId"
              :token="token"
              :detail="detail"
              :selectedTime="selectedTime"
              @hideQuestion="showQuestion(0)"
            ></AskQuestion>
          </div>
          <div
            v-if="item[groupId]"
            class="case-detail"
            v-show="showContentList.indexOf(item[groupId].courseId) > -1"
          >
            <div class="detail-content">
              <h4>讲师简介</h4>
              <p
                class="text"
                v-for="lecturer in item[groupId].lecturers"
                :key="lecturer.lid"
                v-html="lecturer.description"
              ></p>
              <h4>课程简介</h4>
              <p class="text" v-html="item[groupId].courseDesc"></p>
            </div>
            <!-- ppt -->
            <!-- {{item[groupId].courseId}} -->
            <!-- <h4>课程PPT</h4>
            <div class="ppt-con" v-if="pptList[item[groupId].courseId] && pptList[item[groupId].courseId].length>0">
              <slot v-for="(ii) in pptLen[item[groupId].courseId]">
                <slot v-if="pptList[item[groupId].courseId][ii-1]">

                  <img v-image-preview
                    v-if="pptList[item[groupId].courseId][ii-1].url"
                    :src="pptList[item[groupId].courseId][ii-1].url"
                    :key="'ppt_'+'_'+ii" alt="">
                </slot>
              </slot>
              <p v-if="pptLen[item[groupId].courseId] < pptList[item[groupId].courseId].length"
                 @click="pptLen[item[groupId].courseId]+=pptLimit, $forceUpdate()">
                加载后十页图片
              </p>
            </div>
            <p v-else class="ppt-none text">该PPT不能公开展示</p> -->
            <div class="feedback-wrapper">
              <button
                class="feedback-btn"
                @click="courseFeedback(item[groupId].courseId)"
                v-if="global.year!='2022sh'"
              >
                反馈
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="schedule-content mob">
      <ul class="content-list" v-if="detail[selectedTime]">
        <li v-for="(item, index) in detail[selectedTime].all" :key="'live_schedule_list_mob_'+index">
          <div
            class="case-title"
            v-if="item[groupId]"
            @click="toggleContent"
            :data-id="item[groupId].courseId"
          >
            <img :src="item[groupId].lecturers[0].thumbs" class="photo" />
            <div class="lecturer">
              <div class="time">{{ index }}</div>
              <h3 class="title">{{ item[groupId].courseTitle }}</h3>
              <div
                class="teacher"
                v-for="(lecturer, i) in item[groupId].lecturers"
                :key="i"
              >
                {{ lecturer.name }} | {{ lecturer.company }}
                {{ lecturer.position }}
              </div>
            </div>
          </div>
          <div
            v-if="item[groupId]"
            class="case-detail"
            v-show="showContentList.indexOf(item[groupId].courseId) > -1"
          >
            <div class="detail-content">
              <h4>讲师简介</h4>
              <p
                class="text"
                v-for="lecturer in item[groupId].lecturers"
                :key="lecturer.lid"
                v-html="lecturer.description"
              ></p>
              <h4>课程简介</h4>
              <p class="text" v-html="item[groupId].courseDesc"></p>
            </div>
            <div class="feedback-wrapper">
              <button
                class="feedback-btn"
                @click="courseFeedback(item[groupId].courseId)"
                v-if="global.year!='2022sh'"
              >
                反馈
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AskQuestion from "./AskQuestion.vue";
export default {
  components: {
    AskQuestion,
  },
  props: {
    detail: Object,
    selectedTime: String,
    token: String,
  },
  data() {
    return {
      timeList: {},
      showContentList: [],
      groupId: "",
      pptLimit: 10,
      pptList: {},
      pptLen: {},
      selectedQuestion: 0,
    };
  },
  mounted() {
    this.groupId = this.$route.query.groupid;
  },

  methods: {
    courseFeedback(courseId) {
      this.$emit("clickFeedback", courseId);
    },
    showQuestion(courseId) {
      this.selectedQuestion = courseId;
    },
    formatTime(timestamp) {
      const time = new Date(timestamp * 1000);
      return `${time.getMonth() + 1}月${
        time.getDate() < 10 ? "0" + time.getDate() : time.getDate()
      }日`;
    },
    toggleTime(e) {
      this.$emit("changeTime", e.currentTarget.dataset.index);
    },
    toggleContent(e) {
      const courseId = parseInt(e.currentTarget.dataset.id);
      const index = this.showContentList.indexOf(courseId);
      if (index > -1) {
        this.showContentList.splice(index, 1);
      } else {
        this.showContentList.push(courseId);

        // 展开课程时获取ppt
        // console.log("detail ",courseId,Object.keys(this.pptList));
        // if (Object.keys(this.pptList).indexOf(courseId) == -1) {
        //   this.pptList[courseId] = []
        //   this.pptLen[courseId] = 1
        // const uid = localStorage.getItem('userId')
        // let url = this.global.url[this.global.area].ppt
        // const params =
        //   '?sid=' + this.global.area + '&cid=' + courseId + '&userId=' + uid
        // this.$http
        //   .jsonp(url + params)
        //   .then((data) => {
        //     return data.json()
        //   })
        //   .then((res) => {
        //     // console.log(res);
        //     if (res.errno === '0') {
        //       this.$forceUpdate() // 强制刷新视图，但不会刷新数据和状态
        //       this.pptList[courseId] = res.data.attachment.imgs
        //       // console.log("pptList", this.pptList,this.pptLen);
        //     }
        //   })
        // }
      }
    },
  },
};
</script>

<style>
.vue-directive-image-previewer {
  z-index: 1050 !important;
}
</style>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .mob {
    display: none;
  }
}
@media (max-width: 768px) {
  .pc {
    display: none;
  }
}
@media (min-width: 768px) {
  .schedule {
    background: inherit;
    width: 834px;
    .schedule-bar {
      .time-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        li {
          width: 33.33%;
          height: 60px;
          line-height: 60px;
          background: #ffffff;
          font-size: 20px;
          font-weight: 500;
          color: #333;
          text-align: center;
          cursor: pointer;
          &.selected {
            background: #0a70c5;
            color: #ffffff;
          }
          &:first-child {
            border-radius: 5px 0px 0px 5px;
          }
          &:last-child {
            border-radius: 0px 5px 5px 0;
          }
        }
      }
    }
    .schedule-content {
      position: relative;
      .content-list {
        li {
          margin-top: 18px;
          .case-title {
            position: relative;
            background: #ffffff;
            border-radius: 10px;
            display: flex;
            padding: 17px 49px 25px 29px;
            cursor: pointer;
            .photo-wrapper {
              padding-top: 10px;
              width: 76px;
              display: flex;
              flex-direction: column;
              .photo {
                margin-bottom: 12px;
                max-width: 76px;
                height: 76px;
                background: #6c7585;
                border-radius: 5px;
              }
            }
            .lecturer-wrapper {
              flex: 1;
              margin-left: 25px;
              letter-spacing: 1px;
              .title-wrapper {
                border-bottom: 1px solid #e6e6e6;
                padding-bottom: 8px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title {
                  margin: 0;
                  padding: 0 0 4px 0;
                  color: #333333;
                  font-size: 19px;
                  font-weight: 500;
                }
                .ask-question {
                  border: none;
                  outline: none;
                  width: 82px;
                  height: 33px;
                  background: #0a70c5;
                  color: #fff;
                  border-radius: 4px;
                  font-size: 17px;
                }
                .question-list {
                  position: absolute;
                  bottom: -30px;
                  left: 80%;
                }
              }
              .lecturer {
                display: flex;
                flex-direction: column;
              }
              .teacher {
                margin-top: 10px;
                margin-bottom: 8px;
                font-size: 14px;
                color: #333333;
                text-align: left;
                .teacher-name {
                  position: relative;
                  padding-right: 6px;
                  &::after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    content: "";
                    width: 1px;
                    height: 20px;
                    display: block;
                    background-color: #030000;
                  }
                }
                .logo {
                  padding-left: 6px;
                  max-width: 55px;
                }
              }
              .company-time {
                display: flex;
                justify-content: space-between;
                line-height: 15px;
                font-size: 11px;
                font-weight: 400;
                color: #808080;
                .company {
                  padding-right: 80px;
                }
                .time {
                  font-size: 14px;
                }
              }
            }
          }
          .case-detail {
            margin-top: 12px;
            background-color: rgba(251, 251, 251, 1);
            padding: 0 46px 18px 29px;
            letter-spacing: 1px;
            .detail-content {
              padding-right: 58px;
              padding-bottom: 25px;
              border-bottom: 1px solid #e6e6e6;
            }
            h4 {
              padding-top: 28px;
              padding-bottom: 22px;
              color: #4d4d4d;
              font-size: 17px;
              font-weight: 400;
              text-align: left;
            }
            .text {
              color: #808080;
              font-size: 14px;
              line-height: 21px;
              text-align: justify;
            }
            .feedback-wrapper {
              padding-top: 15px;
              text-align: right;
              .feedback-btn {
                outline: none;
                border: none;
                width: 82px;
                height: 33px;
                background: #dce5ef;
                color: #0a70c5;
                border-radius: 4px;
                font-size: 17px;
              }
            }
            .ppt-con {
              img {
                width: 100%;
                margin-bottom: 22px;
              }
              p {
                width: max-content;
                margin: 0 auto;
                color: #4d4d4d;
                font-size: 18px;
                line-height: 40px;
                cursor: pointer;
                padding: 0 30px;
                background-color: #0a70c5;
                border-radius: 20px;
              }
            }
            .ppt-none {
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .schedule {
    background: inherit;
    width: 100%;
    .schedule-bar {
      .time-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        li {
          width: 50%;
          padding-top: 1.5vh;
          padding-bottom: 1.5vh;
          background: #ffffff;
          font-size: 11px;
          color: #333;
          text-align: center;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          &.selected {
            background: #0a70c5;
            color: #fff;
          }
          &:first-child {
            border-radius: 5px 0px 0px 5px;
          }
          &:last-child {
            border-radius: 0px 5px 5px 0;
          }
          .day {
            margin-bottom: 2px;
            font-weight: 500;
          }
        }
      }
    }
    .schedule-content {
      position: relative;
      .content-list {
        li {
          margin-top: 13px;
          width: 100%;
          box-sizing: border-box;
          border-radius: 4px;
          .case-title {
            border-radius: 10px;
            padding: 9px 4vw 10px 4vw;
            display: flex;
            cursor: pointer;
            background: #ffffff;
            .photo {
              width: 16vw;
              height: 16vw;
              background: #6c7585;
              border-radius: 5px;
            }
            .lecturer {
              flex: 1;
              margin-left: 25px;
              letter-spacing: 1px;
              .time {
                margin-bottom: 4px;
                color: #909090;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
              }
              .title {
                margin: 0;
                padding: 0;
                color: #000000;
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                box-orient: vertical;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .teacher {
                margin-top: 6px;
                margin-bottom: 4.5px;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                color: #909090;
                display: flex;
                flex-wrap: wrap;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                box-orient: vertical;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .case-detail {
            margin-top: 12px;
            background-color: #fcfcfc;
            padding: 0 4.6vw 22px 4.3vw;
            letter-spacing: 1px;
            border-radius: 4px;
            .detail-content {
              border-bottom: 1px solid #dce5ef;
              padding-bottom: 12px;
            }
            h4 {
              padding-top: 20px;
              padding-bottom: 10px;
              color: #666666;
              font-size: 13px;
              font-weight: 400;
              text-align: left;
            }
            .text {
              color: #909090;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              text-align: justify;
            }
            .feedback-wrapper {
              padding-top: 10px;
              text-align: right;
              .feedback-btn {
                outline: none;
                border: none;
                width: 60px;
                height: 30px;
                background: #dce5ef;
                color: #0a70c5;
                border-radius: 4px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
